export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  countries: '/countries/',
  states: '/states/',
  cities: '/cities/',
  loginUserEndpoint: '/account/userlogin',
  customer: '/customer/',
  customeruser: '/customeruser/',
  customercenter: '/customercenter/',
  customercenteriec104data: '/customercenter/get_iec104_data/',
  devicetypes: '/devicetypes/',
  deviceanalyzer: '/deviceanalyzer/',
  devicebrand: '/devicebrand/',
  devicebrandfiles: '/devicebrandfiles/',
  devicebrandregistertemplates: '/devicebrandregistertemplates/',
  companycenterdevices: '/companycenterdevices/',
  currencies: '/currencies/',
  companycenterdevicesmonitor: '/companycenterdevicesmonitor/',

}
