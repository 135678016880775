import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000,
    // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response.status === 401) {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')
            ability.update(initialAbility)
            router.push({ name: 'auth-login' })
        }
        return Promise.reject(error)
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns
