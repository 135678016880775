import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getCountries(args) {
    return this.axiosIns.get(this.jwtConfig.countries, { params: args })
  }
  getStates(args) {
    return this.axiosIns.get(this.jwtConfig.states, { params: args })
  }
  getCities(args) {
    return this.axiosIns.get(this.jwtConfig.cities, { params: args })
  }

  loginUser(...args) {
    return this.axiosIns.post(this.jwtConfig.loginUserEndpoint, ...args)
  }

  getCustomerList(args) {
    return this.axiosIns.get(this.jwtConfig.customer, { params: args })
  }

  getCustomerDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.customer}${id}/`)
  }

  updateCustomer(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.customer}${id}/`, ...args)
  }

  createCustomer(...args) {
    return this.axiosIns.post(this.jwtConfig.customer, ...args)
  }

  getCustomerUserList(args) {
    return this.axiosIns.get(this.jwtConfig.customeruser, { params: args })
  }

  createCustomerUser(...args) {
    return this.axiosIns.post(this.jwtConfig.customeruser, ...args)
  }

  customerUserDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.customeruser}${id}/`)
  }

  customerUserDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.customeruser}${id}/`)
  }

  updateCustomerUser(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.customeruser}${id}/`, ...args)
  }

  getCustomerCenterList(args) {
    return this.axiosIns.get(this.jwtConfig.customercenter, { params: args })
  }

  createCustomerCenter(...args) {
    return this.axiosIns.post(this.jwtConfig.customercenter, ...args)
  }

  getCustomerCenterIec104Data(...args) {
    return this.axiosIns.post(this.jwtConfig.customercenteriec104data, ...args)
  }

  customerCenterDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.customercenter}${id}/`)
  }

  updateCustomerCenter(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.customercenter}${id}/`, ...args)
  }

  customerCenterDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.customercenter}${id}/`)
  }

  getDeviceTypeList(args) {
    return this.axiosIns.get(this.jwtConfig.devicetypes, { params: args })
  }

  createDeviceType(...args) {
    return this.axiosIns.post(this.jwtConfig.devicetypes, ...args)
  }

  deviceTypeDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.devicetypes}${id}/`)
  }

  deviceTypeDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.devicetypes}${id}/`)
  }

  updateDeviceType(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.devicetypes}${id}/`, ...args)
  }

  getDeviceAnalyzerList(args) {
    return this.axiosIns.get(this.jwtConfig.deviceanalyzer, { params: args })
  }

  createDeviceAnalyzer(...args) {
    return this.axiosIns.post(this.jwtConfig.deviceanalyzer, ...args)
  }

  deviceAnalyzerDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.deviceanalyzer}${id}/`)
  }

  deviceAnalyzerDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.deviceanalyzer}${id}/`)
  }

  updateDeviceAnalyzer(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.deviceanalyzer}${id}/`, ...args)
  }

  getDeviceBrandList(args) {
    return this.axiosIns.get(this.jwtConfig.devicebrand, { params: args })
  }

  createDeviceBrand(...args) {
    return this.axiosIns.post(this.jwtConfig.devicebrand, ...args)
  }

  deviceBrandDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.devicebrand}${id}/`)
  }

  deviceBrandDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.devicebrand}${id}/`)
  }

  updateDeviceBrand(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.devicebrand}${id}/`, ...args)
  }

  getCompanyCenterDevicesList(args) {
    return this.axiosIns.get(this.jwtConfig.companycenterdevices, { params: args })
  }

  createCompanyCenterDevices(...args) {
    return this.axiosIns.post(this.jwtConfig.companycenterdevices, ...args)
  }

  companycenterdevicesmonitor(...args) {
    return this.axiosIns.post(this.jwtConfig.companycenterdevicesmonitor, ...args)
  }

  detailCompanyCenterDevices(id) {
    return this.axiosIns.get(`${this.jwtConfig.companycenterdevices}${id}/`)
  }

  deleteCompanyCenterDevices(id) {
    return this.axiosIns.delete(`${this.jwtConfig.companycenterdevices}${id}/`)
  }

  updateCompanyCenterDevices(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.companycenterdevices}${id}/`, ...args)
  }

  getCurrenciesList(args) {
    return this.axiosIns.get(this.jwtConfig.currencies, { params: args })
  }

  createCurrencies(...args) {
    return this.axiosIns.post(this.jwtConfig.currencies, ...args)
  }

  detailCurrencies(id) {
    return this.axiosIns.get(`${this.jwtConfig.currencies}${id}/`)
  }

  deleteCurrencies(id) {
    return this.axiosIns.delete(`${this.jwtConfig.currencies}${id}/`)
  }

  updateCurrencies(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.currencies}${id}/`, ...args)
  }

  getDeviceBrandFilesList(args) {
    return this.axiosIns.get(this.jwtConfig.devicebrandfiles, { params: args })
  }

  createDeviceBrandFiles(...args) {
    return this.axiosIns.post(this.jwtConfig.devicebrandfiles, ...args)
  }

  deviceBrandFilesDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.devicebrandfiles}${id}/`)
  }

  deviceBrandFilesDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.devicebrandfiles}${id}/`)
  }

  updateDeviceBrandFiles(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.devicebrandfiles}${id}/`, ...args)
  }

  getDeviceBrandRegisterTemplatesList(args) {
    return this.axiosIns.get(this.jwtConfig.devicebrandregistertemplates, { params: args })
  }

  createDeviceBrandRegisterTemplates(...args) {
    return this.axiosIns.post(this.jwtConfig.devicebrandregistertemplates, ...args)
  }

  DeviceBrandRegisterTemplatesDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.devicebrandregistertemplates}${id}/`)
  }

  DeviceBrandRegisterTemplatesDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.devicebrandregistertemplates}${id}/`)
  }

  updateDeviceBrandRegisterTemplates(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.devicebrandregistertemplates}${id}/`, ...args)
  }

}
