export default [
  {
    path: '/customer/plants',
    name: 'customer-plants',
    component: () => import('@/views/custom_app/customer/plants/Plants.vue'),
    meta: {
      resource: 'client',
      action: 'read',

    },
  },
  {
    path: '/customer/plant-detail/:id',
    name: 'customer-plant-detail',
    component: () => import('@/views/custom_app/components/plants/PlantDetail.vue'),
    meta: {
      resource: 'plant_detail',
      action: 'read',

    },
  },
  {
    path: '/customer/devices',
    name: 'customer-devices',
    component: () => import('@/views/custom_app/customer/devices/Devices.vue'),
    meta: {
      resource: 'client',
      action: 'read',

    },
  },
  {
    path: '/customer/alarms',
    name: 'customer-alarms',
    component: () => import('@/views/custom_app/customer/alarm/Alarms.vue'),
    meta: {
      resource: 'client',
      action: 'read',

    },
  },
]
