export default [
  {
    path: '/customers',
    name: 'customers-list',
    component: () => import('@/views/custom_app/admin/CustomerList.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/customers/:id',
    name: 'customers-management',
    component: () => import('@/views/custom_app/admin/CustomerManagement.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/general_definitions/devicetypes',
    name: 'device_types',
    component: () => import('@/views/custom_app/admin/components/general_definitions/DeviceTypes.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/general_definitions/currencies',
    name: 'currencies',
    component: () => import('@/views/custom_app/admin/components/general_definitions/Currencies.vue'),
    meta: {
      resource: 'admin',

    },
  },
]
